import { formatDate } from "../utils/dates";

export class GammesManager {
  gammes = [];

  constructor() {
    this.storeGammes();
  }

  storeGammes = () => {
    const gammes = document.querySelectorAll('[data-products-ids]');

    gammes.forEach(gamme => {
      this.gammes.push({
        element: gamme,
        productsIds: gamme.dataset.productsIds.split(',').map(id => parseInt(id)),
      });
    });
  }

  updateAvailability = (stays) => {
    if (!stays || stays.length === 0) {
      return;
    }

    const formattedStays = this.formatStays(stays);

    this.gammes.forEach(gamme => {
      const gammeStays = formattedStays.filter(stay => gamme.productsIds.includes(stay.productId))

      if (gammeStays.length > 0) {
        const prices = gammeStays.map(stay => stay.price);
        const bestPrice = Math.min(...prices);
        this.updateGammeAvailability(gamme.element, bestPrice)
      }
    });
  }

  formatStays = stays => stays.map(stay => {
    const bookingFees = parseInt(stay.productPrice.bookingFees);

    const price = stay.productPrice.price > stay.productPrice.finalPrice
      ? parseInt(stay.productPrice.finalPrice) + bookingFees
      : parseInt(stay.productPrice.price) + bookingFees;

    return {
      price,
      productId: stay.productId
    }
  });

  updateGammeAvailability = (el, price) => {
    const priceValueEl = el.querySelector('.price .value');

    if (priceValueEl) {
      priceValueEl.textContent = price;
      el.classList.add('has-values');
      el.parentNode.classList.add('has-values-parentnode');
      el.classList.remove('no-value');
    }
  }

  setLoading = () => {
    this.gammes.forEach(gamme => {
      gamme.element.classList.remove('has-values');
      gamme.element.parentNode.classList.remove('has-values-parentnode');
      gamme.element.classList.add('no-value');
      gamme.element.classList.add('gamme-loading');
    });
  }

  stopLoading = () => {
    this.gammes.forEach(gamme => {
      gamme.element.classList.remove('gamme-loading');
    });
  }

  hasProducts = () => this.gammes.length > 0;
}
