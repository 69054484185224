import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"


export class SearchFormManager {
  localStorageID = 'pdt_search_data';
  initialValues = {};
  submitFormEvent = new CustomEvent("pdt-submit-form");


  constructor() {
    this.form = document.getElementById('searchform');

    if (!this.form) {
      return;
    }

    this.inputs = {
      startDate: document.getElementById('start-date'),
      endDate: document.getElementById('end-date'),
      daterange: document.getElementById('daterange-input'),
      travelers: document.getElementById('travelers'),
      gammes: document.getElementById('gamme-select'),
      submit: document.getElementById('pdt-submit'),
      inputflatpicker: document.getElementById('daterange-input'),
    };

    this.mode = this.form.dataset.mode ? this.form.dataset.mode : 'redirect'; // 'submit' | 'dateChange'


    this.elements = document.querySelectorAll('.wp-block-plune-blocks-accommodation-preview');


    this.setInitialValues();
    this.initDatePicker();
    this.initEventListeners();
    this.storeSearchValues();
  }

  setInitialValues = () => {
    this.initialValues = this.generateDefaultDates();

    const localStorageValues = JSON.parse(localStorage.getItem(this.localStorageID));
    const now = new Date();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 0, 0, 0);

    if (localStorageValues?.startDate) {
      if (new Date(localStorageValues.startDate) < endOfDay) {
        this.initialValues.startDate = endOfDay
      }
      else {
        this.initialValues.startDate = new Date(localStorageValues.startDate)
      }
    }

    if (localStorageValues?.endDate) {
      if (new Date(localStorageValues.startDate) < endOfDay) {
        const endOfDayPlus7 = new Date(endOfDay);  // Copie de endOfDay
        endOfDayPlus7.setDate(endOfDayPlus7.getDate() + 7);
        this.initialValues.endDate = endOfDayPlus7
      }
      else {
        this.initialValues.endDate = new Date(localStorageValues.endDate);
      }
    } else if (localStorageValues?.startDate) {
      this.initialValues.endDate = new Date(this.initialValues.startDate);
      this.initialValues.endDate.setDate(
          this.initialValues.startDate.getDate() + 7
      );
    }

    this.initialValues.travelers = localStorageValues?.travelers
        ? localStorageValues.travelers
        : 1;

    if (this.inputs.travelers) {
      this.inputs.travelers.value = this.initialValues.travelers;
    }

    if (this.inputs.gammes) {
      this.form.setAttribute('action', this.inputs.gammes.value+"#godispo");
      let url = window.location.origin + window.location.pathname;
      if (url == this.inputs.gammes.value) {
        this.form.setAttribute('data-mode', 'submit');
        this.mode = 'submit';
      }
      else {
        this.form.setAttribute('data-mode', 'redirect');
        this.mode = 'redirect';
      }
    }

    this.elements.forEach((element) => {
      const capacity = parseInt(element.getAttribute('data-capacity'));
      if (this.inputs.travelers.value > 0) {
        if (capacity < this.inputs.travelers.value) {
          element.parentNode.classList.add('hide');
        }
      }
    });
  }

  initDatePicker = () => {





    this.inputs.startDate.value = this.formatDate(this.initialValues.startDate);
    this.inputs.endDate.value = this.formatDate(this.initialValues.endDate);


    flatpickr(this.inputs.inputflatpicker, {
      defaultDate: [this.initialValues.startDate, this.initialValues.endDate],
      mode: "range",
      dateFormat: "d/m/Y",
      locale: French,
      minDate: this.initialValues.minDate,
      maxDate: this.initialValues.maxDate,
      onChange: (selectedDates, dateStr, instance) => {
        this.inputs.startDate.value = flatpickr.formatDate(selectedDates[0], 'd/m/Y');
        if (selectedDates.length === 2) {
          this.inputs.endDate.value = flatpickr.formatDate(selectedDates[1], 'd/m/Y');
        }
        this.storeSearchValues();

        if (this.mode === 'dateChange') {
          document.dispatchEvent(this.submitFormEvent);
        }
      }
    });

  }


  initEventListeners = () => {
    this.form.addEventListener('submit', event => {
      if (this.mode === 'submit') {
        event.preventDefault();
        this.form.dispatchEvent(this.submitFormEvent);
      }
    });

    this.inputs.startDate.addEventListener('click', e => {
      e.preventDefault();
      this.inputs.daterange.click();
    });

    this.inputs.endDate.addEventListener('click', e => {
      e.preventDefault();
      this.inputs.daterange.click();
    });

    if (this.inputs.travelers) {
      this.inputs.travelers.addEventListener('change', e => {
        this.form.dispatchEvent(this.submitFormEvent);
        this.storeSearchValues();

        this.elements.forEach((element) => {
          const capacity = parseInt(element.getAttribute('data-capacity'));
          if (this.inputs.travelers.value > 0) {
            if (capacity < this.inputs.travelers.value) {
              element.parentNode.classList.add('hide');
            }
            else {
              element.parentNode.classList.remove('hide');
            }
          }
        });
      });
    }


    if (this.inputs.gammes) {
      this.inputs.gammes.addEventListener('change', e => {
        this.form.setAttribute('action', e.currentTarget.value+"#godispo");
        let url = window.location.origin + window.location.pathname;
        if (url == e.currentTarget.value) {
          this.form.setAttribute('data-mode', 'submit');
          this.mode = 'submit';
        }
        else {
          this.form.setAttribute('data-mode', 'redirect');
          this.mode = 'redirect';
        }
      });
    }
  }


  generateDefaultDates() {
    const today = new Date();

    let startDate = null,
        endDate = null,
        minDate = new Date(pdt_config.seasonStartDate),
        maxDate = new Date(pdt_config.seasonEndDate);

    const getNextSaturday = (fromDate) => {
      const date = new Date(fromDate);
      const day = date.getDay(); // 0 = dimanche, 6 = samedi
      const daysUntilSaturday = (6 - day + 7) % 7 || 7;
      date.setDate(date.getDate() + daysUntilSaturday);
      return date;
    };

    if (today < minDate) { // Avant ouverture saison
      startDate = getNextSaturday(pdt_config.seasonStartDate);
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 7);
    } else if (today < maxDate) { // Pendant saison
      startDate = getNextSaturday(today);
      minDate = new Date();
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 7);
    } else { // Après saison
      minDate = new Date();
      minDate.setDate(minDate.getDate() - 1);
      maxDate = new Date();
      startDate = new Date(minDate);
      endDate = new Date(maxDate);
    }

    return { startDate, endDate, minDate, maxDate };
  }

  storeSearchValues = () => {
    const searchData = {
      startDate: this.inputs.startDate.value.split('/').reverse().join('-'),
      endDate: this.inputs.endDate.value.split('/').reverse().join('-'),
      travelers: parseInt(this.inputs.travelers ? this.inputs.travelers.value : 1),
    };

    localStorage.setItem(
        this.localStorageID,
        JSON.stringify(searchData)
    );
  }

  formatDate = date => {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  getCurentValues = () => {
    return {
      startDate: this.inputs.startDate.value.split('/').reverse().join('-'),
      endDate: this.inputs.endDate.value.split('/').reverse().join('-'),
      travelers: this.inputs.travelers ? this.inputs.travelers.value : 1,
    };
  }

  getFormElement = () => this.form;

  getCustomSubmitEventType = () => this.submitFormEvent.type;
}